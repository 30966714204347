import { useSenderAccountContext } from "context/SenderAccountContext";
import { useGetOntology } from "core/model/utils/ontologies/hooks";
import { Roles } from "core/types";
import { ChipGroupContainer } from "ds_legacy/components/ChipGroup";
import InfoChip from "ds_legacy/components/InfoChip";
import { margin } from "ds_legacy/materials/metrics";
import {
  extractDistinctCareproviderRoles,
  getCareseekerRoles,
} from "dsl/organisms/Accounts";

export function CareseekerAccountRolesChip({
  disabled,
  value,
}: {
  disabled?: boolean;
  value?: Roles;
}) {
  const { careseeker } = useSenderAccountContext();

  if (!careseeker)
    throw new Error(
      `[${CareseekerAccountRolesChip.name}] must be used within the SenderAccountContext`,
    );

  const getOntology = useGetOntology();

  const careseekerRoles = getCareseekerRoles(value, careseeker.id);

  return (
    <ChipGroupContainer margin="0">
      {careseekerRoles?.sort().map((role) => {
        return (
          <div key={role} style={{ margin: margin(0.5) }}>
            <InfoChip
              color="primary"
              label={getOntology({ type: "accountRoleShort", key: role })}
              variant="outlined"
              disabled={disabled}
            />
          </div>
        );
      })}
    </ChipGroupContainer>
  );
}

export function CareproviderAccountRolesChip({
  careproviderId,
  disabled,
  value,
}: {
  careproviderId: number | null;
  disabled?: boolean;
  value?: Roles;
}) {
  const getOntology = useGetOntology();

  if (!careproviderId) {
    console.error("careproviderId is missing in CareproviderAccountRolesChip");
    return null;
  }

  const roles = extractDistinctCareproviderRoles(value, careproviderId);

  return (
    <ChipGroupContainer margin="0">
      {roles?.sort().map((role) => {
        return (
          <div key={role} style={{ margin: margin(0.5) }}>
            <InfoChip
              color="primary"
              label={getOntology({ type: "accountRoleShort", key: role })}
              variant="outlined"
              disabled={disabled}
            />
          </div>
        );
      })}
    </ChipGroupContainer>
  );
}
