import { useSendFaxForAuctionRequest } from "apollo/hooks/mutations";
import {
  CAREPROVIDER_INACTIVE,
  FAX_TYPE_ATTRIBUTION,
  FAX_TYPE_REQUEST,
} from "core/consts";
import { useDateLocale } from "core/hooks/useDateLocale";
import Config, { ENV_PRODUCTION } from "core/model/config";
import { getErrorMessage } from "core/model/utils/errors";
import { useGetOntology } from "core/model/utils/ontologies/hooks";
import { Auction, AuctionRequest, Careprovider } from "core/types";
import { extractPatientFields } from "dsl/organisms/PatientMenu/transform";
import { useCallback } from "react";
import { useLocale, useTranslations } from "translations";
import { activateFax, isFaxReceiver, isFaxRequest, isFaxSender } from ".";
import { usePatientEncryption } from "../Contexts";
import { getPatientProfileHTML } from "./getPatientProfileHTML";

export function useFaxPatient() {
  const [sendFax] = useSendFaxForAuctionRequest();
  const { currentLocale } = useDateLocale();
  const getOntology = useGetOntology();
  const locale = useLocale();
  const translations = useTranslations();
  const { encryptionAvailable } = usePatientEncryption();

  const faxAuctionRequests = useCallback(
    ({
      auction,
      newRequests,
    }: {
      auction: Auction;
      newRequests: AuctionRequest[] | undefined;
    }) => {
      if (!isFaxSender({ auction: auction }) || !auction?.patient) return;

      const inactiveRequests = newRequests
        ?.map((request) => {
          const isInactive = isFaxReceiver(request?.careprovider);
          if (isInactive) return request.id;
          return false;
        })
        .truthy();

      if (inactiveRequests && inactiveRequests.length > 0) {
        const patientProfileHTML = getPatientProfileHTML({
          auction,
          currentLocale,
          encryptionAvailable,
          extractedPatientFields: extractPatientFields(auction),
          getOntology,
          locale,
          translations,
          type: FAX_TYPE_REQUEST,
        });

        const sendAllFaxesPromise = Promise.all(
          inactiveRequests?.map((requestId: number) =>
            sendFax({
              variables: {
                auctionRequestId: requestId,
                input: {
                  content: patientProfileHTML,
                  fax_type: FAX_TYPE_REQUEST,
                },
              },
            }),
          ),
        );
        return sendAllFaxesPromise
          .then((responses) => {
            const urls = responses.map(({ data }) => {
              return decodeURIComponent(data?.fax.url ?? "");
            });

            // LOGGING
            if (Config.environment !== ENV_PRODUCTION) {
              let message = "Faxes sent for:\n\n";
              newRequests
                ?.map((request) => {
                  const { id, name, status } = request.careprovider ?? {};
                  const isInactive = status === CAREPROVIDER_INACTIVE;
                  if (isInactive) return { id, name };
                  return false;
                })
                .truthy()
                .forEach(({ id, name }, idx) => {
                  message += `#${id} ${name}\n${urls[idx]}\n`;
                });

              console.log(message);
            }

            return responses;
          })
          .catch((err) => {
            console.error("[Error sending fax]: ", getErrorMessage(err));
          });
      }
    },
    [currentLocale, getOntology, isFaxReceiver, locale, sendFax, translations],
  );

  const faxAuctionRequest = useCallback(
    (auction: Auction | undefined, request: AuctionRequest) => {
      const inactiveRequest = isFaxRequest(request);
      if (!inactiveRequest || !auction?.patient || !isFaxSender({ auction }))
        return;

      const patientProfileHTML = getPatientProfileHTML({
        auction,
        currentLocale,
        encryptionAvailable,
        extractedPatientFields: extractPatientFields(auction),
        getOntology,
        locale,
        translations,
        type: FAX_TYPE_REQUEST,
      });

      return sendFax({
        variables: {
          auctionRequestId: request.id,
          input: {
            content: patientProfileHTML,
            fax_type: FAX_TYPE_REQUEST,
          },
        },
      })
        .then((response: AnyObject | undefined) => {
          // LOGGING
          if (Config.environment === ENV_PRODUCTION) return;

          const url = decodeURIComponent(response?.data.fax.url);

          if (url) {
            console.log(`Faxes sent for request #${request?.id}\n${url}`);
          }
        })
        .catch((err) =>
          console.error(
            `error faxing request #${request?.id}\n`,
            getErrorMessage(err),
          ),
        );
    },
    [currentLocale, getOntology, isFaxReceiver, locale, sendFax, translations],
  );

  const faxAuctionWinner = useCallback(
    ({
      auction,
      careprovider,
    }: {
      auction: Auction;
      careprovider: Careprovider;
    }) => {
      const requestId = auction?.winner?.auction_request_id;

      if (
        !auction?.patient ||
        !activateFax({
          auction,
          careprovider,
        })
      )
        return;

      if (requestId) {
        const patientProfileHTML = getPatientProfileHTML({
          auction,
          currentLocale,
          encryptionAvailable,
          extractedPatientFields: extractPatientFields(auction),
          getOntology,
          locale,
          translations,
          type: FAX_TYPE_ATTRIBUTION,
        });

        return sendFax({
          variables: {
            auctionRequestId: requestId,
            input: {
              content: patientProfileHTML,
              fax_type: FAX_TYPE_ATTRIBUTION,
            },
          },
        })
          .then((response: AnyObject | undefined) => {
            // LOGGING
            if (Config.environment === ENV_PRODUCTION) return;

            const url = decodeURIComponent(response?.data.fax.url);

            if (url) {
              console.log(`Faxes sent for winner #${careprovider?.id}\n${url}`);
            }
            return response;
          })
          .catch((err) =>
            console.error(
              `error faxing winner #${careprovider?.id}\n`,
              getErrorMessage(err),
            ),
          );
      }
    },
    [currentLocale, getOntology, isFaxReceiver, locale, sendFax, translations],
  );

  return { faxAuctionRequests, faxAuctionRequest, faxAuctionWinner };
}
