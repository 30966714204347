import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "ds_legacy/components/Tooltip";
import { GREY_500 } from "ds_legacy/materials/colors";
import { margin, sizing } from "ds_legacy/materials/metrics";
import { FONT_SIZE_16, LongMenuText } from "ds_legacy/materials/typography";
import { Menu } from "dsl/atoms/Menu";
import { useDropdown } from "dsl/hooks";
import { kebabCase } from "lodash";
import { MoreVerticalIcon } from "lucide-react";
import { MouseEvent, MouseEventHandler, ReactElement } from "react";
import styled from "styled-components";

export type LongMenuOption = {
  disabled?: boolean;
  icon?: ReactElement;
  label: string;
  onClick: MouseEventHandler;
  tooltip?: string;
};

export type LongMenuProps = {
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  options: LongMenuOption[];
  size?: "large" | "medium" | "small";
  testId?: string;
};

const LONG_MENU_ITEM_HEIGHT = 48;

const StyledMenu = styled(Menu)`
  margin-top: ${margin(6)};
`;

// Allow displaying tooltips on disabled menu items
const StyledMenuItem = styled(MenuItem)`
  &&.Mui-disabled {
    pointer-events: inherit;
    cursor: inherit;
  }
  &&.Mui-disabled:active {
    pointer-events: none;
    cursor: default;
  }
`;

const LONG_MENU_ID = "long-button";

function LongMenu({
  onClick: onMenuClick,
  options,
  size = "large",
  testId,
}: LongMenuProps) {
  const { anchor, onClose, onOpen, open } = useDropdown();

  return (
    <div>
      <IconButton
        // #TODO: use translations for aria-label
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        aria-label="more"
        id={LONG_MENU_ID}
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          onMenuClick?.(e);
          onOpen(e);
        }}
        data-testid={testId}
        size={size}
      >
        <MoreVerticalIcon size={FONT_SIZE_16} />
      </IconButton>
      <StyledMenu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": LONG_MENU_ID,
        }}
        anchorEl={anchor}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={open}
        onClose={onClose}
        slotProps={{
          paper: {
            style: {
              maxHeight: LONG_MENU_ITEM_HEIGHT * 4.5,
              minWidth: sizing(20),
            },
          },
        }}
      >
        {options.map(({ disabled, icon, label, onClick, tooltip }, index) => (
          <Tooltip key={index} title={tooltip}>
            <StyledMenuItem
              data-testid={`${testId}-${kebabCase(label)}`}
              dense
              disabled={disabled}
              onClick={(event) => {
                onClick(event);
                onClose();
              }}
              tabIndex={index}
            >
              {icon && (
                <ListItemIcon
                  style={{
                    minWidth: "max-content",
                    marginRight: margin(1),
                    color: GREY_500,
                  }}
                >
                  {icon}
                </ListItemIcon>
              )}
              <LongMenuText>{label}</LongMenuText>
            </StyledMenuItem>
          </Tooltip>
        ))}
      </StyledMenu>
    </div>
  );
}
export default LongMenu;
